import { computed, nextTick, ref, watch, type ComputedRef, type Ref } from "vue"
import type { UnionToIntersection, ConditionalKeys, KeysOfUnion } from "type-fest"

type AnyRef<T> = Ref<T> | ComputedRef<T>

/**
 * Finds the first searchable property in an object from a list of keys and returns it as a string.
 * 
 * @template T - The type of the object.
 * @template TSearchableProperty - The type of the keys that can be searched within the object.
 * 
 * @param {T} object - The object to search within.
 * @param {TSearchableProperty[]} keys - An array of keys to search for within the object.
 * 
 * @returns The value of the first found property as a string, or an empty string if none of the properties are found.
 */
function findSearchableProperty<T extends object, TSearchableProperty extends KeysOfUnion<T>>(object: T, keys: TSearchableProperty[]) {
  return String(keys.reduce<T[TSearchableProperty] | undefined>((memo, property) => memo ?? object[property], undefined) ?? '')
}

/**
 * Tokenizes a given string into an array of words. The string is first trimmed and converted to lowercase.
 * If the input string is undefined or null, an empty array is returned.
 * 
 * @param {string | undefined | null} string - The input string to be tokenized.
 * 
 * @returns An array of words from the tokenized string. Returns an empty array if the input is undefined or null.
 */
function tokenizeTerm (string: string | undefined | null) {
  const cleanString = string?.trim().toLowerCase()

  if (cleanString) {
    return cleanString.split(/\s+/)
  } else {
    return []
  }
}

/**
 * Compares two arrays of tokens to determine if the sequence of tokens to find exists within the tokens.
 * 
 * @param {string[]} tokensToFind - The array of tokens to find within the other array.
 * @param {string[]} tokens - The array of tokens to be searched.
 * 
 * @returns Returns true if the sequence of tokens to find exists within the tokens, otherwise false.
 */
function compareTokens (tokensToFind: string[], tokens: string[]) {
  if (tokens.length === 0 || tokensToFind.length === 0) return false
  for (let i = 0; i < tokens.length; i++) {
    if (tokens.length - i < tokensToFind.length) {
      // There is not enough tokens to match against, return false
      return false
    } else if (tokens[i].startsWith(tokensToFind[0])) {
      for (let j = 1; j <= tokensToFind.length; j++) {
        // Hacky way to ensure that we always return true on the last token
        if (j === tokensToFind.length) return true
        // Break out of the loop if a token does not match the token we are looking for
        else if (!tokens[i + j].startsWith(tokensToFind[j])) {
          break
        }
      }
    }
  }

  return false
}

/**
 * Filters a collection of objects based on a search term and specified keys to search through.
 * 
 * @template T - The type of the objects in the collection.
 * @template TSearchableProperty - The type of the keys that can be searched within the objects.
 * 
 * @param {T[]} collection - The collection of objects to filter.
 * @param {string | undefined | null} searchTerm - The search term used to filter the collection.
 * @param {...TSearchableProperty[]} searchThroughKeys - The keys to search through within each object in the collection.
 * 
 * @returns The filtered collection of objects.
 */
export function getFilteredCollection<T extends object, TSearchableProperty extends KeysOfUnion<T>>(
  collection: T[],
  searchTerm: string | undefined | null,
  ...searchThroughKeys: TSearchableProperty[]
) {
  const tokens = tokenizeTerm(searchTerm)
  if (tokens.length > 0) {
    return collection.filter((object) => compareTokens(tokens, tokenizeTerm(findSearchableProperty(object, searchThroughKeys))))
  } else {
    return collection
  }
}

export function useSearch<T extends object, TSearchableProperty extends KeysOfUnion<T>, TSearchableNestedProperty extends ConditionalKeys<UnionToIntersection<T>, T[]>>(
  collection: AnyRef<T[]>,
  collectionSearchableProperties: TSearchableProperty[],
  searchElementVisible: AnyRef<boolean>,
  collectionSearchableNestedProperty?: TSearchableNestedProperty
) {
  const searchElement = ref<HTMLInputElement>()
  const searchModel = ref('')
  const searchClear = () => searchModel.value = ''
  const searchValue = computed(() => searchModel.value.trim())

  const getFilteredCollection = (tokens: string[], collection: T[]) => collection.reduce<T[]>((memo, object) => {
    if (typeof collectionSearchableNestedProperty !== 'undefined' && collectionSearchableNestedProperty in object) {
      const nestedCollection = getFilteredCollection(tokens, object[collectionSearchableNestedProperty as unknown as keyof T] as T[])

      if (nestedCollection.length > 0) {
        memo.push({
          ...object,
          [collectionSearchableNestedProperty]: nestedCollection
        })
      }
    } else if (compareTokens(tokens, tokenizeTerm(findSearchableProperty(object, collectionSearchableProperties)))) {
      memo.push(object)
    }

    return memo
  }, [])

  const getFlatCollection = (collection: T[], nestingLevel = 0): (T & { _nestingLevel: number })[] => {
    return collection.flatMap((object) => {
      if (typeof collectionSearchableNestedProperty !== 'undefined' && collectionSearchableNestedProperty in object) {
        return [
          {
            ...object,
            _nestingLevel: nestingLevel
          },
          ...getFlatCollection(
            object[collectionSearchableNestedProperty as unknown as keyof T] as T[],
            nestingLevel + 1
          )
        ]
      } else {
        return {
          ...object,
          _nestingLevel: nestingLevel
        }
      }
    })
  }

  watch(searchElementVisible, (isVisible) => {
    if (isVisible) void nextTick(() => searchElement.value?.focus())
    else {
      searchClear()
    }
  })

  const searchCollection = computed(() => {
    const tokens = tokenizeTerm(searchValue.value)
    if (tokens.length > 0) {
      return getFilteredCollection(tokens, collection.value)
    } else {
      return collection.value
    }
  })

  const searchCollectionFlat = computed(() => getFlatCollection(searchCollection.value))

  return {
    searchElement,
    searchModel,
    searchCollection,
    searchCollectionFlat,
    searchClear,
    searchValue
  }
}